$commit-height: 20px;
$commit-entry-space: 5px;
$header-height: 30px;

$link-color: #688094;

body {
  font-family: Verdana, Arial, sans-serif;
  margin: 0;
  font-size: 12px;
}

::-moz-selection {
  color: white;
  background: #525252;
}

::selection {
  color: white;
  background: #525252;
}

gitline-headerpanel {
  background-color: #f2f2f2;
  width: 100%;
  display: block;
  padding-left: 6px;
  font-size: 14px;
  z-index: 5;
  position: relative;
  height: $header-height;
  line-height: $header-height;
  border-bottom: 1px solid darkgray;
  box-sizing: border-box;

  a {
    color: $link-color;

    &:hover {
      color: black;
    }
  }
}

gitline-loadingpanel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  z-index: 4;
  background-color: #f2f2f2;
  font-size: 20px;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}

gitline-textpanel {
  z-index: 2;
  min-width: 300px;

  .gitline-expandable {
    cursor: pointer;
  }

  gitline-legend {
    border-bottom: 1px solid #e0e0e0;
    min-width: 250px;
    min-height: $commit-height;
    display: block;

    * {
      vertical-align: middle;
      display: inline-block;
    }

    gitline-subject {
      color: black;
      display: inline;
      line-height: 22px; // for multi line

      &.has-merges {
        color: #bbb;
      }
    }

    gitline-sha {
      color: $link-color;
      font-size: 13px;
      padding-right: $commit-entry-space;
      font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono,
      DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, sans-serif;
    }

    gitline-ref {
      padding: 1px 3px 1px 3px;
      color: white;
      margin-right: $commit-entry-space;
    }

    gitline-identity-container {
      padding-right: $commit-entry-space;

      gitline-identity {
        min-width: $commit-height;
        min-height: $commit-height;
        white-space: nowrap;
		background-size: $commit-height $commit-height !important;

        &.gitline-expandable-expanded {
          padding-left: ($commit-height + 4px);
          font-style: italic;
          line-height: $commit-height;
        }
      }

      gitline-identity-datetime {
        font-style: italic;
        color: $link-color;
        padding-left: $commit-entry-space;
      }
    }
  }
}

gitline-graphicalpanel {
  z-index: 3;
  position: absolute !important;
  display: block;
}